import React, { useState, useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { AdminRoute, UserRoute, PublicRoute } from "./PrivateRoutes";
import { getLocationData, navigateTo } from "./services/Router";
import { AuthContext } from "./context/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserPlan, ifAdminRoleDisabled } from "./services/User";
import ScrollToTop from "./widgets/ScrollToTop";
import UnderDevelopment from "./pages/UnderDevelopment";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { setActiveLanguage } from "./services/Misc";
import Modal from "react-modal";
import customStyles from "./constants/Styles";
import { Icons } from "./constants/Icons";
import Img from "./elements/Img";
import "./assets/css/inventory.css";
import axios from "axios";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyATqR4cQX7yLEt_W8_nz1x2z_gNfFtg1ag",
  authDomain: "creditq-e1b52.firebaseapp.com",
  databaseURL: "https://creditq-e1b52.firebaseio.com",
  projectId: "creditq-e1b52",
  storageBucket: "creditq-e1b52.appspot.com",
  messagingSenderId: "382387228148",
  appId: "1:382387228148:web:102ace5a0757e6d5e8fda1",
  measurementId: "G-9W3JRE98TR",
};

function App() {
  useEffect(() => {
    console.log(
      "logggggggggggggqqqqqqqqqqq",
      process.env.REACT_APP_SHOW_CONSOLE_LOGS
    );

    if (process.env.REACT_APP_SHOW_CONSOLE_LOGS === "FALSE") {
      console.log("loggggggggggggg", process.env.REACT_APP_SHOW_CONSOLE_LOGS);
      console.log = () => {};
      console.error = () => {};
      console.debug = () => {};
    }
  });
  if (localStorage.getItem("activeLanguage") === null) {
    setActiveLanguage("en");
  }
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  // Initialize Performance Monitoring and get a reference to the service
  const perf = getPerformance(app);
  const [authTokens, setAuthTokens] = useState(
    localStorage.getItem("state") || ""
  );
  const [userDetails, setUserDetails] = useState(false);

  const [isOpen, setisOpen] = useState(false);
  // const [maintenanceModeOn, setmaintenanceModeOn] = useState(process.env.REACT_APP_NODE_ENV ==='staging' && sessionStorage.getItem('authUser')!=='yes');
  const [maintenanceModeOn, setmaintenanceModeOn] = useState("");
  const setTokens = (data) => {
    localStorage.setItem("state", JSON.stringify(data));
    setAuthTokens(data);
  };
  const renderStyles = () => {
    let activeRoute = getLocationData();
    if (activeRoute.href.search("admin") !== -1) {
      document.getElementById("root").classList = "admin";
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
      require("./assets/scss/admin/styles.scss");
      require("./assets/scss/styles.scss");
    } else {
      document.getElementById("root").classList = activeRoute.pathname.replace(
        /\//g,
        ""
      );
      document.getElementById("root").classList = "front";
      require("./assets/scss/styles.scss");
    }
  };

  useEffect(() => {
    if (authTokens) {
      getUserDetails(JSON.parse(authTokens).token);
    } else {
      setUserDetails({});
    }
  }, []);

  useEffect(() => {
    console.log("userDetails####", userDetails);
    renderStyles();
  }, [userDetails]);
  const getUserDetails = async (token) => {
    const checkIfAdminRoleIsDisabled = await ifAdminRoleDisabled(token);
    if (checkIfAdminRoleIsDisabled.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login";
      return;
    }
    let result = await getUserPlan(token);
    if (result.status) {
      if (result.data.status === 0) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/login";
        return;
      }
      setUserDetails(result.data);
      localStorage.accessAction = JSON.stringify(result.data.role.accessAction);
    }
  };
  const switchMaintenance = () => {
    setmaintenanceModeOn = false;
  };

  const closeModal = () => {
    setisOpen(false);
  };
  const openModal = () => {
    setisOpen(true);
  };

  return (
    <>
      {userDetails && (
        <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
          {maintenanceModeOn ? (
            <UnderDevelopment switchMaintenance={switchMaintenance} />
          ) : (
            <Suspense fallback={<div>Loading...</div>}>
              <Router>
                <ScrollToTop />
                <Switch>
                  {/* Public Routes Starts */}
                  <PublicRoute
                    path="/"
                    component={lazy(() => import("./pages/Home"))}
                    exact
                  />
                  <PublicRoute
                    page="login"
                    path="/login"
                    component={lazy(() => import("./pages/Login"))}
                  />

                  {/*
                     <PublicRoute
                    path="/page/:query"
                    page="page/terms-and-conditions"
                    component={lazy(() => import("./pages/Page"))}
                  />
                  <PublicRoute
                    path="/about-us"
                    component={lazy(() => import("./pages/About"))}
                  />
                  <PublicRoute
                    path="/page"
                    component={lazy(() => import("./pages/Page"))}
                  />
                  <PublicRoute
                    path="/user-manual"
                    component={lazy(() => import("./pages/UserManual"))}
                  />
                  <PublicRoute
                    path="/contact-us"
                    component={lazy(() => import("./pages/Contact"))}
                  />
                  <PublicRoute
                    path="/faq"
                    component={lazy(() => import("./pages/Faq"))}
                    exact
                  />
                  <PublicRoute
                    path="/career"
                    component={lazy(() => import("./pages/Career"))}
                  />
                  <PublicRoute
                    path="/solutions"
                    component={lazy(() => import("./pages/AboutJourney"))}
                  />
                  <PublicRoute
                    path="/membership"
                    component={lazy(() => import("./pages/Membership"))}
                  />
                  <PublicRoute
                    path="/blog"
                    component={lazy(() => import("./pages/Blog"))}
                    exact
                  />
                  <PublicRoute
                    path="/job-detail"
                    component={lazy(() => import("./pages/JobDetail"))}
                  />
                  <PublicRoute
                    path="/post/:slug"
                    component={lazy(() => import("./pages/Post"))}
                  />
                  <PublicRoute
                    path="/user-guide"
                    component={lazy(() => import("./pages/UserManual"))}
                    />
                    */}
                  <PublicRoute
                    path="/acknowledgement/:key"
                    component={lazy(() => import("./pages/Acknoeledgement"))}
                  />
                  <PublicRoute
                    path="/voucher/:key"
                    component={lazy(() => import("./pages/Login"))}
                  />
                  <PublicRoute
                    path="/redirect/auth"
                    component={lazy(() => import("./pages/RedirectAuth"))}
                  />
                  {/* Post Login Routes Starts */}
                  <UserRoute
                    userDetails={userDetails}
                    exact
                    path="/business-transaction"
                    component={lazy(() =>
                      import("./pages/BusinessTransaction")
                    )}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    exact
                    path="/purchase-transaction/:buyerId"
                    component={lazy(() =>
                      import("./pages/PurchaseTransaction")
                    )}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    exact
                    path="/admin/wallet/transaction/:userId"
                    component={lazy(() =>
                      import("./pages/admin/walletTransaction")
                    )}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    exact
                    path="/purchase-transaction"
                    component={lazy(() =>
                      import("./pages/PurchaseTransaction")
                    )}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    path="/view-business-transaction"
                    component={lazy(() =>
                      import("./pages/TradeTransactionDetail")
                    )}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    path="/my-profile"
                    component={lazy(() => import("./pages/Profile"))}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    path="/business-dashboard"
                    component={lazy(() => import("./pages/BusinessDashboard"))}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    path="/defaulter"
                    component={lazy(() => import("./pages/Defaulter"))}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    path="/payment-success"
                    component={lazy(() => import("./pages/PaymentSuccess"))}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    path="/payment-error"
                    component={lazy(() => import("./pages/PaymentSuccess"))}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    path="/payment-pending"
                    component={lazy(() => import("./pages/PaymentSuccess"))}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    path="/defaulter-detail/:defaulterId"
                    component={lazy(() => import("./pages/DefaulterDetail"))}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    path="/subscription/renew/:businessId"
                    component={lazy(() => import("./pages/Subscription"))}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    path="/subscription"
                    component={lazy(() => import("./pages/Subscription"))}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    path="/tutorial"
                    component={lazy(() => import("./pages/Tutorial"))}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    path="/my-invoices"
                    component={lazy(() => import("./pages/MyInvoices"))}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    exact
                    path="/dashboard"
                    component={lazy(() => import("./pages/Dashboard"))}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    exact
                    path="/dashboard/agreement/:businessIdForAgreement"
                    component={lazy(() => import("./pages/Dashboard"))}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    path="/cir-report"
                    component={lazy(() => import("./pages/CIRReport"))}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    path="/cir-view"
                    component={lazy(() => import("./pages/CIRReportView"))}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    path="/wallet"
                    component={lazy(() => import("./pages/Wallet"))}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    exact
                    path="/admin/user"
                    component={lazy(() => import("./pages/admin/Users"))}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    path="/business-transaction-defaulter/:supplierId,:buyerId"
                    component={lazy(() =>
                      import("./pages/ReportCreditDefaulter")
                    )}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    path="/acknowledgement/:key"
                    component={lazy(() => import("./pages/Acknoeledgement"))}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    path="/Inventoryentries/:voucherId"
                    component={lazy(() => import("./pages/Inventoryentry"))}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    exact
                    path="/Settlement/defaulter/:params"
                    component={lazy(() =>
                      import("./pages/SettlementDefaulter")
                    )}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    exact
                    path="/settlement-requests"
                    component={lazy(() =>
                      import("./pages/Settlement/Requests/")
                    )}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    exact
                    path="/settlement-requests/details/:settlementId"
                    component={lazy(() =>
                      import("./pages/Settlement/Requests/details")
                    )}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    exact
                    path="/settlement/sign-letter/:defaulterCaseId/:doctype"
                    component={lazy(() =>
                      import("./pages/Settlement/Esign-letter")
                    )}
                    accessRoles={["customer", "admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    exact
                    path="/settlement/verify-bank/:gstn/:srIdbankId"
                    component={lazy(() =>
                      import("./pages/Settlement/Bank-verify")
                    )}
                    accessRoles={["customer", "admin"]}
                  />
                  <AdminRoute
                    userDetails={userDetails}
                    exact
                    path="/admin/smsLog/list"
                    component={lazy(() =>
                      import("./pages/admin/AdminSmsLogList/")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/ip/management"
                    component={lazy(() =>
                      import("./pages/admin/IpManagement/index.js")
                    )}
                    accessRoles={["admin"]}
                  />
                  <UserRoute
                    userDetails={userDetails}
                    path="/new/supplier/consent/agreement/:customerId?"
                    component={lazy(() =>
                      import("./pages/Agreement/newSupplierAgreement.js")
                    )}
                    accessRoles={["customer", "admin"]}
                  />
                  {/* Shorten URL Route */}
                  <PublicRoute
                    path="/p/:slug"
                    component={lazy(() => import("./components/ShortenURL"))}
                  />
                  {/* Admin Routes Starts */}
                  <PublicRoute
                    path="/export/:uuid"
                    component={lazy(() =>
                      import("./pages/admin/ExportDataByAdmin/downloadCsv")
                    )}
                  />

                  <UserRoute
                    userDetails={userDetails}
                    exact
                    path="/settlement/verify-bank/:gstn/:srIdbankId"
                    component={lazy(() =>
                      import("./pages/Settlement/Bank-verify")
                    )}
                    accessRoles={["customer", "admin"]}
                  />
                  {/* Shorten URL Route */}
                  <PublicRoute
                    path="/p/:slug"
                    component={lazy(() => import("./components/ShortenURL"))}
                  />
                  {/* Admin Routes Starts */}
                  <PublicRoute
                    path="/export/:uuid"
                    component={lazy(() =>
                      import("./pages/admin/ExportDataByAdmin/downloadCsv")
                    )}
                  />

                  <AdminRoute
                    path="/admin/view-business-transaction"
                    component={lazy(() =>
                      import("./pages/admin/ViewBusinessTransaction")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/defaulter-detail/:defaulterId"
                    component={lazy(() =>
                      import("./pages/admin/AdminDefaulterDetail")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/customer-detail/:customerId"
                    component={lazy(() =>
                      import("./pages/admin/AdminCustomerDetail")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/master-data/business-categories"
                    component={lazy(() =>
                      import("./pages/admin/BusinessCategory")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/master-data/cities"
                    component={lazy(() => import("./pages/admin/Cities"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/settlement/call-request-hold"
                    component={lazy(() =>
                      import("./pages/admin/CallRequestHold")
                    )}
                    accessRoles={["admin"]}
                    exact
                  />
                  <AdminRoute
                    path="/admin/settlement/call-request-hold/details/:holdId"
                    component={lazy(() =>
                      import("./pages/admin/HoldRequestView")
                    )}
                    accessRoles={["admin"]}
                    exact
                  />
                  <AdminRoute
                    path="/admin/customer-management/defaulter"
                    component={lazy(() =>
                      import("./pages/admin/DefaulterList")
                    )}
                    accessRoles={["admin"]}
                    exact
                  />
                  <AdminRoute
                    path="/admin/defaulter/contact-details"
                    component={lazy(() =>
                      import("./pages/admin/DefaulterContactsList")
                    )}
                    accessRoles={["admin"]}
                    exact
                  />
                  <AdminRoute
                    path="/admin/defaulter/report-defaulter"
                    component={lazy(() =>
                      import("./pages/admin/ReportDefaulterByAdmin")
                    )}
                    accessRoles={["admin"]}
                    exact
                  />
                  <AdminRoute
                    path="/admin/defaulter/defaulter-settlements"
                    component={lazy(() =>
                      import("./pages/admin/DefaulterSettlementRequests")
                    )}
                    accessRoles={["admin"]}
                    exact
                  />
                  <AdminRoute
                    path="/admin/defaulter/defaulter-settlements/:id"
                    component={lazy(() =>
                      import(
                        "./pages/admin/DefaulterSettlementRequests/defaulterSettlementRequestDetails.js"
                      )
                    )}
                    accessRoles={["admin"]}
                    exact
                  />

                  <AdminRoute
                    path="/admin/users/walletTransaction"
                    component={lazy(() =>
                      import("./pages/admin/WalletTransactionList")
                    )}
                    accessRoles={["admin"]}
                    exact
                  />
                  <AdminRoute
                    path="/admin/customer-management/deactivate-user-list"
                    component={lazy(() =>
                      import("./pages/admin/DeactivateUser")
                    )}
                    accessRoles={["admin"]}
                    exact
                  />
                  <AdminRoute
                    path="/admin/customer-management/relationship-manager"
                    component={lazy(() =>
                      import("./pages/admin/RelationshipManager")
                    )}
                    accessRoles={["admin"]}
                    exact
                  />
                  <AdminRoute
                    path="/admin/customer-management/bitrix-management"
                    component={lazy(() =>
                      import("./pages/admin/Bitrix/bitrixAgentsList.js")
                    )}
                    accessRoles={["admin"]}
                    exact
                  />
                  <AdminRoute
                    path="/admin/relationship/manager/:rmId"
                    component={lazy(() =>
                      import(
                        "./pages/admin/RelationshipManager/relationshipManagerDetails.js"
                      )
                    )}
                    accessRoles={["admin"]}
                    exact
                  />
                  <AdminRoute
                    path="/admin/users/walletTransaction/details/:userId"
                    component={lazy(() =>
                      import(
                        "./pages/admin/WalletTransactionList/walletTransactionDetilsListView.js"
                      )
                    )}
                    accessRoles={["admin"]}
                    exact
                  />
                  <AdminRoute
                    path="/admin/customer-management/defaulter/report-bulk"
                    component={lazy(() =>
                      import("./pages/admin/DefaulterList")
                    )}
                    accessRoles={["admin"]}
                    exact
                  />
                  <AdminRoute
                    path="/admin/customer-management/defaulter/report"
                    component={lazy(() =>
                      import("./pages/admin/DefaulterList")
                    )}
                    accessRoles={["admin"]}
                    exact
                  />
                  <AdminRoute
                    path="/admin/list-views/cashback"
                    component={lazy(() => import("./pages/admin/CashbackList"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/list-views/business-transactions"
                    component={lazy(() =>
                      import("./pages/admin/BusinessTransactionLIst")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/list-views/sync-tracking"
                    component={lazy(() =>
                      import("./pages/admin/BcmSyncTracking")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/bcm/registration"
                    component={lazy(() =>
                      import("./pages/admin/BcmRegistration")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/bcm/registrationAttempts"
                    component={lazy(() =>
                      import("./pages/admin/BcmRegistrationAttempts")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/bcm/voucherAttempts"
                    component={lazy(() =>
                      import("./pages/admin/voucherAttempts")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/bcm/gstApiErrorLog"
                    component={lazy(() =>
                      import("./pages/admin/BcmGstApiErrorLog")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/bcm/syncVoucherHistory"
                    component={lazy(() =>
                      import("./pages/admin/syncVoucherHistory")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    exact
                    path="/admin/bcm/error-log"
                    component={lazy(() => import("./pages/admin/BcmErrorLog"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/bcm/error-log/:paramFile"
                    component={lazy(() => import("./pages/admin/BcmErrorLog"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/list-views/wallet-cashback"
                    component={lazy(() =>
                      import("./pages/admin/WalletCashbackList")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/setting/wallet"
                    component={lazy(() =>
                      import("./pages/admin/WalletSetting")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/setting/payment"
                    component={lazy(() =>
                      import("./pages/admin/PaymentConvenienceFee")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/global/setting"
                    component={lazy(() =>
                      import("./pages/admin/GlobalSetting")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/campaign/list"
                    component={lazy(() => import("./pages/admin/CampaignList"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/transation-bill-details/:buyerId,:supplierId"
                    exact
                    component={lazy(() =>
                      import("./pages/admin/NoOfBillDetail")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/customer-management/customers"
                    component={lazy(() => import("./pages/admin/CustomerList"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/customer-management/customer/view"
                    component={lazy(() => import("./pages/admin/CustomerList"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/promotion-management/free-trial"
                    component={lazy(() =>
                      import("./pages/admin/FreeTrialList")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/promotion-management/promo-code"
                    component={lazy(() =>
                      import("./pages/admin/PromoCodeList")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/promo-code/Details-view/:id"
                    component={lazy(() =>
                      import("./pages/admin/PromoCodeDetailsView")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cin/list"
                    component={lazy(() => import("./pages/admin/cinHit/index"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cin/error/list/:batch"
                    component={lazy(() =>
                      import("./pages/admin/cinHit/errorCin")
                    )}
                    accessRoles={["admin"]}
                  />
                  {/* <AdminRoute
                    path="/admin/promotion-management/promo-code/detail-view/:code"
                    component={lazy(() =>
                      import("./pages/admin/PromoCodeDetailsView")
                    )}
                    accessRoles={["admin"]}
                  /> */}
                  <AdminRoute
                    path="/admin/list-views/invoices"
                    component={lazy(() => import("./pages/admin/InvoiceList"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/invoice-detailView/:id"
                    component={lazy(() =>
                      import("./pages/admin/InvoiceDetailView")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/list-views/commissions"
                    component={lazy(() =>
                      import("./pages/admin/CommissionList")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/Detail-views/commissions/:id"
                    component={lazy(() =>
                      import("./pages/admin/CommissionDetailView")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/list-views/audit-logs"
                    component={lazy(() =>
                      import("./pages/admin/AdminAuditLogs")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/list-views/gst-hit-list"
                    component={lazy(() => import("./pages/admin/gstHitLogs"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/setting/gst/hit"
                    component={lazy(() =>
                      import("./pages/admin/gstHitSettings")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/partner-management/affiliates"
                    component={lazy(() =>
                      import("./pages/admin/AffiliatePrograme")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/dashboard"
                    component={lazy(() =>
                      import("./pages/admin/AdminDashboard")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/admin-user-management/admin-role"
                    exact
                    component={lazy(() =>
                      import("./pages/admin/AdminRoleList")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/admin-user-management/admin-role/add"
                    component={lazy(() => import("./pages/admin/AddUserRole"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/admin-user-management/admin-role/edit"
                    component={lazy(() => import("./pages/admin/AddUserRole"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/admin-user-management/admin-user"
                    component={lazy(() =>
                      import("./pages/admin/AdminUserList")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/admin-user-management/restricted-ip"
                    component={lazy(() => import("./pages/admin/RestrictedIp"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/ip/add"
                    component={lazy(() =>
                      import("./pages/admin/AddRestrictedIp")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/customer-management/businesses"
                    component={lazy(() =>
                      import("./pages/admin/BusinessAdminList")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/customer-management/business/view/:businessId"
                    component={lazy(() =>
                      import("./pages/admin/BusinessAdminView")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/setting/membership-plan"
                    component={lazy(() =>
                      import("./pages/admin/MembershipSetting")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/setting/reset"
                    component={lazy(() => import("./pages/admin/Reset"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/setting/bcm/notification"
                    component={lazy(() =>
                      import("./pages/admin/BcmNotification")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/setting/upload/tcp"
                    component={lazy(() => import("./pages/admin/TcpUpload"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/setting/otp"
                    component={lazy(() =>
                      import("./pages/admin/TempOtpGenerate")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/setting/contact/block"
                    component={lazy(() =>
                      import("./pages/admin/BlockContactDetails")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/promotion-management/campaign/list"
                    component={lazy(() => import("./pages/admin/CampaignList"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/promotion-management/campaign/add"
                    component={lazy(() => import("./pages/admin/AddCampaign"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/promotion-management/campaign/edit"
                    component={lazy(() => import("./pages/admin/AddCampaign"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/promotion-management/campaign/view/:campaignId"
                    component={lazy(() => import("./pages/admin/ViewCampaign"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cms/page/list"
                    component={lazy(() => import("./pages/admin/PagesList"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cms/page/add"
                    component={lazy(() => import("./pages/admin/AddPage"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cms/page/edit"
                    component={lazy(() => import("./pages/admin/AddPage"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cms/home"
                    component={lazy(() => import("./pages/admin/HomeCMS"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cms/about"
                    component={lazy(() => import("./pages/admin/AboutCMS"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cms/media"
                    component={lazy(() => import("./pages/admin/Media"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/setting/template/list"
                    component={lazy(() => import("./pages/admin/Template"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/setting/template/add"
                    component={lazy(() => import("./pages/admin/AddTemplate"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/setting/template/edit"
                    component={lazy(() => import("./pages/admin/AddTemplate"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/partner-management/professionals"
                    component={lazy(() =>
                      import("./pages/admin/Professionals")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/partner-management/channel-partner"
                    component={lazy(() =>
                      import("./pages/admin/ChannelPartners")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/setting/commissions"
                    component={lazy(() =>
                      import("./pages/admin/CommissionSetting")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cms/user-manual/list"
                    component={lazy(() =>
                      import("./pages/admin/UserManualAdmin")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cms/user-manual/add"
                    component={lazy(() =>
                      import("./pages/admin/AddUserManual")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cms/user-manual/edit"
                    component={lazy(() =>
                      import("./pages/admin/AddUserManual")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/user/view"
                    component={lazy(() => import("./pages/admin/AddUser"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/user/edit"
                    component={lazy(() => import("./pages/admin/AddUser"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/user/add"
                    component={lazy(() => import("./pages/admin/AddUser"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/features/keys/add"
                    component={lazy(() => import("./pages/admin/KeyFeatures"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/features/keys/edit"
                    component={lazy(() => import("./pages/admin/KeyFeatures"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/creditq/journey/add"
                    component={lazy(() => import("./pages/admin/AddJourney"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/creditq/journey/edit"
                    component={lazy(() => import("./pages/admin/AddJourney"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/team/add"
                    component={lazy(() => import("./pages/admin/AddTeam"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/team/edit"
                    component={lazy(() => import("./pages/admin/AddTeam"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/list-views/cir"
                    component={lazy(() => import("./pages/admin/CirList"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/sponsor/add"
                    component={lazy(() => import("./pages/admin/AddSponsor"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/sponsor/edit"
                    component={lazy(() => import("./pages/admin/AddSponsor"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/benefits/add"
                    component={lazy(() =>
                      import("./pages/admin/AddBenefitsOfCreditQ")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/benefits/edit"
                    component={lazy(() =>
                      import("./pages/admin/AddBenefitsOfCreditQ")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cms/faq/list"
                    component={lazy(() => import("./pages/admin/Faq"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cms/post/list"
                    component={lazy(() => import("./pages/admin/PostsList"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cms/post/add"
                    component={lazy(() => import("./pages/admin/AddPost"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cms/post/edit"
                    component={lazy(() => import("./pages/admin/AddPost"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cms/job/list"
                    component={lazy(() => import("./pages/admin/JobList"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cms/job/add"
                    component={lazy(() => import("./pages/admin/AddJob"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cms/job/edit"
                    component={lazy(() => import("./pages/admin/AddJob"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cms/testimonial/list"
                    component={lazy(() =>
                      import("./pages/admin/TestimonialList")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cms/testimonial/add"
                    component={lazy(() =>
                      import("./pages/admin/AddTestimonial")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/cms/testimonial/edit"
                    component={lazy(() =>
                      import("./pages/admin/AddTestimonial")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/settlement/commissions"
                    component={lazy(() =>
                      import("./pages/admin/Settlement/Commissions/")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/settlement/services-charges"
                    component={lazy(() =>
                      import("./pages/admin/Settlement/ServicesCharges/")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/settlement/payment-detail/:id"
                    component={lazy(() =>
                      import(
                        "./pages/admin/Settlement/servicesChargesDetailsView"
                      )
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/settlement/documents"
                    component={lazy(() =>
                      import("./pages/admin/Settlement/Documents/")
                    )}
                    accessRoles={["admin"]}
                    exact
                  />
                  <AdminRoute
                    path="/admin/settlement/documents/new"
                    component={lazy(() =>
                      import("./pages/admin/Settlement/Documents/new")
                    )}
                    accessRoles={["admin"]}
                    exact
                  />
                  <AdminRoute
                    path="/admin/settlement/documents/new/:parentId"
                    component={lazy(() =>
                      import("./pages/admin/Settlement/Documents/new")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/settlement/requests"
                    component={lazy(() =>
                      import("./pages/admin/Settlement/Requests/")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/header/document/settlement"
                    component={lazy(() =>
                      import("./pages/admin/Settlement/Documents/headerlist")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/Settlement/header/new"
                    component={lazy(() =>
                      import("./pages/admin/Settlement/Documents/header")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/settlement/details/:settlementId"
                    component={lazy(() =>
                      import("./pages/admin/Settlement/details")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/settlement/instant-cashback"
                    component={lazy(() =>
                      import("./pages/admin/InstantCashbackList")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/legal-notice/documents"
                    component={lazy(() =>
                      import("./pages/admin/legalNotice/Documents")
                    )}
                    accessRoles={["admin"]}
                    exact
                  />
                  <AdminRoute
                    path="/admin/legal-notice/documents/new"
                    component={lazy(() =>
                      import("./pages/admin/legalNotice/Documents/new")
                    )}
                    accessRoles={["admin"]}
                    exact
                  />
                  <AdminRoute
                    path="/admin/legal-notice/documents/new/?id=id&type=edit"
                    component={lazy(() =>
                      import("./pages/admin/legalNotice/Documents/new")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/legal-notice/documents/footer-header"
                    component={lazy(() =>
                      import(
                        "./pages/admin/legalNotice/Documents/headerFooterList"
                      )
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/legal-notice/footer-header/new"
                    component={lazy(() =>
                      import(
                        "./pages/admin/legalNotice/Documents/header_footer"
                      )
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/legal-notice/list"
                    component={lazy(() =>
                      import("./pages/admin/legalNotice/NoticeList/index")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/legal-notice/process"
                    component={lazy(() =>
                      import(
                        "./pages/admin/legalNotice/NoticeList/processNotice"
                      )
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/legal-notice/batch"
                    component={lazy(() =>
                      import("./pages/admin/legalNotice/NoticeList/legalBatch")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/legal-notice/sa-defaulter"
                    component={lazy(() =>
                      import(
                        "./pages/admin/legalNotice/NoticeList/legalDefaulterSA.js"
                      )
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/legal-notice/pdf-generated"
                    component={lazy(() =>
                      import(
                        "./pages/admin/legalNotice/NoticeList/legalNoticeReadyToSigned"
                      )
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/gst/logs"
                    component={lazy(() => import("./pages/admin/gstHit/index"))}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/expire/user/logs"
                    component={lazy(() =>
                      import("./pages/admin/ExpireUserLog/index")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/user/login/restriction/logs"
                    component={lazy(() =>
                      import("./pages/admin/UserLoginRestrictionLogs/index")
                    )}
                    accessRoles={["admin"]}
                  />
                  <AdminRoute
                    path="/admin/export-data/list"
                    component={lazy(() =>
                      import("./pages/admin/ExportDataByAdmin/index")
                    )}
                    accessRoles={["admin"]}
                  />

                  <AdminRoute
                   path="/admin/apiconsuption/logs"
                   component={lazy(()=>
                   import("./pages/admin/ApiConsuption/index.js")
                  
                  )}
                  accessRoles={["admin"]}
                   />

                  {/* Custom Redirect Starts */}

                  {/*<PublicRoute path="/contact" redirectTo="/contact-us" exact />
                  // <PublicRoute path="/about" redirectTo="/about-us" exact />
                       <PublicRoute
                    path="/page/terms-and-conditions"
                    redirectTo="/page-terms-and-conditions/"
                    exact
                  />

                  // <PublicRoute
                  //   path="/credit-information-report"
                  //   redirectTo="/page/credit-information-report"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/report-business-credit-defaulters"
                  //   redirectTo="/page/report-business-credit-defaulters"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/scope-of-settle-payment"
                  //   redirectTo="/page/scope-of-settle-payment"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/business-credit-management"
                  //   redirectTo="/page/business-credit-management"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/membership-plan"
                  //   redirectTo="/membership"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/privacy-policy"
                  //   redirectTo="/page/privacy-policy"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/solutions.html"
                  //   redirectTo="/solutions"
                  //   exact
                  // />
                  // <PublicRoute path="/solution" redirectTo="/solutions" exact />
                  // <PublicRoute path="/events" redirectTo="/" exact />
                  // <PublicRoute path="/help" redirectTo="/" exact />
                  // <PublicRoute path="/home" redirectTo="/" exact />
              
                  // <PublicRoute
                  //   path="/blog/why-should-you-check-your-business-credit-score-regularly/"
                  //   redirectTo="/post/why-should-you-check-your-business-credit-score-regularly/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/company-credit-score-vs-personal-credit-score/"
                  //   redirectTo="/post/company-credit-score-vs-personal-credit-score/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/what-is-in-your-business-credit-report/"
                  //   redirectTo="/post/what-is-in-your-business-credit-report/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/what-msmes-expect-from-budget-2021-to-put-behind-the-covid-19-affect/"
                  //   redirectTo="/post/what-msmes-expect-from-budget-2021-to-put-behind-the-covid-19-affect/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/union-budget-2021-key-announcements-for-the-msme-sector/"
                  //   redirectTo="/post/union-budget-2021-key-announcements-for-the-msme-sector/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/creditq-helps-you-settle-payments-from-business-credit-defaulters/"
                  //   redirectTo="/post/creditq-helps-you-settle-payments-from-business-credit-defaulters/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/how-to-take-control-of-your-business-finances/"
                  //   redirectTo="/post/how-to-take-control-of-your-business-finances/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/reasons-your-business-credit-score-just-dropped-and-what-to-do-about-it/"
                  //   redirectTo="/post/reasons-your-business-credit-score-just-dropped-and-what-to-do-about-it/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/how-important-is-a-maximum-business-credit-score-for-small-business/"
                  //   redirectTo="/post/how-important-is-a-maximum-business-credit-score-for-small-business/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/how-do-business-credit-monitoring-services-work/"
                  //   redirectTo="/post/how-do-business-credit-monitoring-services-work/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/the-pros-and-cons-of-buying-an-existing-business/"
                  //   redirectTo="/post/the-pros-and-cons-of-buying-an-existing-business/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/providing-a-financial-wellness-program-to-employees-can-benefit-your-business/"
                  //   redirectTo="/post/providing-a-financial-wellness-program-to-employees-can-benefit-your-business/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/protecting-your-business-from-b2b-defaulters/"
                  //   redirectTo="/post/protecting-your-business-from-b2b-defaulters/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/how-to-build-a-strong-relationship-as-a-business-owner/"
                  //   redirectTo="/post/how-to-build-a-strong-relationship-as-a-business-owner/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/how-to-build-business-credit-when-paying-your-business-expenses/"
                  //   redirectTo="/post/how-to-build-business-credit-when-paying-your-business-expenses/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/how-to-improve-your-business-credit-management/"
                  //   redirectTo="/post/how-to-improve-your-business-credit-management/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/what-should-you-know-about-business-financing/"
                  //   redirectTo="/post/what-should-you-know-about-business-financing/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/why-is-a-buyer-supplier-relationship-important-in-business/"
                  //   redirectTo="/post/why-is-a-buyer-supplier-relationship-important-in-business/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/improving-your-business-future-tips-to-help-you-improve-your-business-credit-rating/"
                  //   redirectTo="/post/improving-your-business-future-tips-to-help-you-improve-your-business-credit-rating/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/how-to-prepare-your-business-for-another-pandemic-wave/"
                  //   redirectTo="/post/how-to-prepare-your-business-for-another-pandemic-wave/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/lockdown-learning-how-to-level-up-your-business-financial-know-how/"
                  //   redirectTo="/post/lockdown-learning-how-to-level-up-your-business-financial-know-how/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/did-you-make-your-2021-business-financial-checklist/"
                  //   redirectTo="/post/did-you-make-your-2021-business-financial-checklist/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/the-importance-of-credit-management-in-business/"
                  //   redirectTo="/post/the-importance-of-credit-management-in-business/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/credit-management-techniques-to-safeguard-your-business-from-defaulters/"
                  //   redirectTo="/post/credit-management-techniques-to-safeguard-your-business-from-defaulters/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/how-to-generate-credit-information-report-on-creditq"
                  //   redirectTo="/post/how-to-generate-credit-information-report-on-creditq/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/how-to-create-your-financial-and-credit-roadmap"
                  //   redirectTo="/post/how-to-create-your-financial-and-credit-roadmap/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/10-ways-to-manage-your-business-finances-effectively"
                  //   redirectTo="/post/10-ways-to-manage-your-business-finances-effectively/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/how-to-generate-company-cibil-report"
                  //   redirectTo="/post/how-to-generate-company-cibil-report/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/why-proper-invoicing-is-important-for-any-business"
                  //   redirectTo="/post/why-proper-invoicing-is-important-for-any-business/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/why-indian-government-has-come-in-support-of-small-industries-business-units"
                  //   redirectTo="/post/why-indian-government-has-come-in-support-of-small-industries-business-units/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/how-modi-govt-relief-package-helps-msme-atmanirbhar-bharat-abhiyan"
                  //   redirectTo="/post/how-modi-govt-relief-package-helps-msme-atmanirbhar-bharat-abhiyan/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/corona-virus-affects-travel-business-most-check-out-its-direct-impact-on-indian-economy"
                  //   redirectTo="/post/corona-virus-affects-travel-business-most-check-out-its-direct-impact-on-indian-economy/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/what-is-the-effect-of-bank-scams-in-the-indian-economy"
                  //   redirectTo="/post/what-is-the-effect-of-bank-scams-in-the-indian-economy/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/what-is-the-economic-impact-of-corona-virus-in-india"
                  //   redirectTo="/post/what-is-the-economic-impact-of-corona-virus-in-india/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/what-are-the-significant-factors-of-slowing-down-the-indian-economy"
                  //   redirectTo="/post/what-are-the-significant-factors-of-slowing-down-the-indian-economy/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/understanding-key-benefits-of-msme-registration-in-india"
                  //   redirectTo="/post/understanding-key-benefits-of-msme-registration-in-india/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/key-benefits-of-having-good-business-credit-score"
                  //   redirectTo="/post/key-benefits-of-having-good-business-credit-score/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/how-to-improve-and-maintain-healthy-business-credit-score"
                  //   redirectTo="/post/how-to-improve-and-maintain-healthy-business-credit-score/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/what-is-government-performance-and-credit-rating-scheme-for-msme"
                  //   redirectTo="/post/what-is-government-performance-and-credit-rating-scheme-for-msme/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/difference-between-the-definition-of-old-new-msme-in-india"
                  //   redirectTo="/post/difference-between-the-definition-of-old-new-msme-in-india/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/heres-why-msme-sector-is-lagging-behind"
                  //   redirectTo="/post/heres-why-msme-sector-is-lagging-behind/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/how-to-protect-your-business-from-business-defaulters"
                  //   redirectTo="/post/how-to-protect-your-business-from-business-defaulters/"
                  //   exact
                  // />
                  // <PublicRoute
                  //   path="/blog/life-of-a-business-face-to-face-with-defaulters"
                  //   redirectTo="/post/life-of-a-business-face-to-face-with-defaulters/"
                  //   exact
                  // />  
                  {/* Custom Redirect Ends */}
                  <PublicRoute
                    component={lazy(() => import("./pages/NotFound"))}
                  />
                </Switch>
              </Router>
            </Suspense>
          )}
        </AuthContext.Provider>
      )}
      <ToastContainer
        position="top-right"
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        autoClose={3000}
      />

      {/* <div>
        <Img
          className="resend-action-button ticketForm"
          src={Icons["ticketform"]}
          onClick={() => {
            openModal();
          }}
        ></Img>
        <span className="ticketFormText">How may I help You !</span>
      </div> */}
      {Object.keys(userDetails).length == 0 ||
      (userDetails && userDetails.role.id === 1) ? (
        <>
          <div>
            <Img
              className="resend-action-button ticketForm"
              src={Icons["ticketform"]}
              onClick={() => {
                openModal();
              }}
            ></Img>
            <span className="ticketFormText">How may I help You !</span>
          </div>
          <div class="wa_cta">
            <a
              href="https://api.whatsapp.com/send/?phone=918504862035&amp;text&amp;type=phone_number&amp;app_absent=0"
              target="_blank"
            >
              <img
                decoding="async"
                width="63"
                height="64"
                src="https://creditq.in/wp-content/uploads/2024/02/whatsapp-icon.png"
                alt=""
                title="whatsapp-icon"
                class="wp-image-2048"
              />
            </a>
          </div>
        </>
      ) : (
        <></>
      )}

      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="close" onClick={closeModal}>
          <ion-icon name="close-outline"></ion-icon>
        </button>
        <div>
          <iframe
            title="Ticket From"
            frameborder="0"
            border="0"
            cellspacing="0"
            style={{ height: "700px", width: "553px" }}
            src="./ticketForm.html"
          ></iframe>
        </div>
      </Modal>
    </>
  );
}
export default App;
